<template>
  <div class="corporateInformation-view bgfff">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm d-flex w350 flex-wrap-y"
    >
      <el-form-item prop="name" class="w50p">
        <p class="cgray">
          姓名<span style="color: #ff5c6c; margin-left: 5px">*</span>
        </p>
        <el-input
          v-model="ruleForm.name"
          style="width: 240px; margin-right: 8px"
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item prop="phone" class="w50p">
        <p class="cgray">
          手机号<span style="color: #ff5c6c; margin-left: 5px">*</span>
        </p>
        <el-input
          v-model="ruleForm.phone"
          style="width: 240px; margin-right: 8px"
          placeholder="请输入11位的手机号码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="personalWx" class="w50p">
        <p class="cgray">
          微信号
        </p>
        <el-input
          v-model="ruleForm.personalWx"
          style="width: 240px; margin-right: 8px"
          placeholder="请输入微信号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="companyWx" class="w50p">
        <p class="cgray">企业微信</p>
        <el-input
          v-model="ruleForm.companyWx"
          style="width: 240px; margin-right: 8px"
          placeholder="请输入企业微信"
        ></el-input>
      </el-form-item>
      <el-form-item prop="email" class="w50p">
        <p class="cgray">邮箱</p>
        <el-input
          v-model="ruleForm.email"
          style="width: 240px; margin-right: 8px"
          placeholder="请输入邮箱"
        ></el-input>
      </el-form-item>
      <el-form-item prop="position" class="w50p">
        <p class="cgray">职位</p>
        <el-input
          v-model="ruleForm.position"
          style="width: 240px; margin-right: 8px"
          placeholder="请输入职位"
        ></el-input>
      </el-form-item>
      <el-form-item prop="createCompanyName" class="w50p">
        <p class="cgray">公司</p>
        <el-input
          v-model="ruleForm.createCompanyName"
          style="width: 240px; margin-right: 8px"
          placeholder="请输入公司名称"
        ></el-input>
      </el-form-item>
      <el-form-item prop="customerRemark" class="w50p">
        <p class="cgray">备注</p>
        <el-input
          v-model="ruleForm.customerRemark"
          style="width: 240px; margin-right: 8px"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
      <el-form-item size="medium" prop="announcements" class="w100p edit-label">
        <p class="cgray">标签</p>
        
        <span
          class=" mr30 hover_pointer"
          :class="currentType == index ? 'cblue' : ''"
          @click="labelTab_tap(index)"
          v-for="(item, index) in labelTab"
          :key="index"
          ><i :class="item.icon" class="mr10"></i>{{ item.title }}</span>
        <div class="d-flex">
          <div v-for="(item,index) in onConfirmTab" :key="index"
               :class="(item && item.lableId) ? 'select-label' : '' "
               :style="{
                 background: (item && item.lableColor)  ? item.lableColor : '',
                 color: (item && item.lableColor &&  item.lableColor !== '#F5F5F6')  ? '#ffffff' : '#454A4C',
                 marginRight: (item && item.lableId) ? '10px' : '' 
               }">
              {{ (item && item.lableName ) ? item.lableName :  ''  }}
          </div>
        </div>


        <el-dialog
          class="label_dialog"
          title="添加标签组"
          width="30%"
          center
          :visible.sync="addDialogDetailVisible"
          :close-on-click-modal="false"
          :modal-append-to-body="false"
        >
          <div class="mb20" v-for="(item, index) in tags_lists" :key="index">
            <div class="cA2">{{ item.lableName }}</div>
            <div class="pl30 d-flex flex-wrap-y">

              <!-- :class="checkTab(v.id, index) ? 'shadow-gray' : ''" -->
              <div
                class="pl20 pr20 ml20 mb20 br20 hover_pointer"
                :class="v.isSelect  ? '  trans2' : 'bbD7E0 trans2'"
                :style="{
                  background: v.isSelect ? v.lableColor : '',
                  color: !v.isSelect ? '#737F80' : v.lableColor !== '#F5F5F6' ? '#ffffff' : '#737F80' ,
                  border: v.isSelect ?  `1px solid `+ v.lableColor : ''
                }"
                v-for="(v, k) in item.list"
                :key="k"
                @click="chooseTag(v, index, k)"
              >
                {{ v.lableName }}
              </div>
            </div>
          </div>
          <div class="flex-c-c">
            <el-button @click="resetTap()">重置</el-button>
            <el-button
              class="save"
              type="primary"
              @click="onConfirmTap()"
              >确定</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          class="label_dialog"
          title="编辑标签组"
          width="30%"
          center
          :visible.sync="editDialogDetailVisible"
          :modal-append-to-body="false"
          :close-on-click-modal="false"
          @closed="editDialogClose"
        >
          <div class="mb20" v-for="(item, index) in tags_lists" :key="index">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb20">
                <span class="cgray mr24">*</span>
                <el-input
                  v-model="item.lableName"
                  style="width: 300px; margin-right: 30px"
                  placeholder="请输入备注"
                ></el-input>
                <span class="cgray mr30 hover_pointer" @click="delete_label(index)"
                  >删除</span
                >
                <span class="cblue hover_pointer" v-if="!item.list" @click="addLabel(index)"
                  >添加子标签</span
                >
              </div>
              <div
                class="d-flex align-items-center pl29"
                :class="item.list.length - 1 == k ? ' ' : 'mb10'"
                v-for="(v, k) in item.list"
                :key="k"
              >
                <el-input
                  v-model="v.lableName"
                  style="width: 240px; margin-right: 30px"
                  placeholder="请输入备注"
                ></el-input>
                <el-color-picker
                  v-model="v.lableColor"
                  size="mini"
                  :clearable="false"
                  popper-class="color-picker"
                  ref="colorpicker"
                  @active-change="color_picker"
                  :predefine="predefineColors"
                ></el-color-picker>
                <span class="cgray mr30 ml10 hover_pointer" @click="delete_label(index, k)"
                  >删除</span
                >
                <span
                  class="cblue mr30 hover_pointer"
                  v-if="item.list.length - 1 == k"
                  @click="addLabel(index)"
                  >添加子标签</span
                >
              </div>
            </div>
          </div>

          <div class="hover_pointer" @click="addLabels()">添加标签组</div>
          <div class="flex-c-c">
            <el-button @click="resetEditDialog">取消</el-button>
            <el-button
              class="save"
              type="primary"
              @click="saveTagsTree"
              >确定</el-button
            >
          </div>
        </el-dialog>
      </el-form-item>
      <el-form-item prop="announcements" class="w100p">
        <p class="cgray">共享范围</p>
        <el-radio v-model="ruleForm.customerState" :label="1">自己独占</el-radio>
        <el-radio v-model="ruleForm.customerState" :label="2">企业共享</el-radio>
      </el-form-item>
      <el-form-item class="w100p">
        <el-button class="save" type="primary" @click="submitForm('ruleForm')" :disabled="!hasPermi"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { fetchClient,addLableList,getCustomerInfo,updCustomer,manualAdd } from "@/api/client";
export default {
  name: "customer-input",
  data() {
    return {
      hasPermi: true,
      activeName: "first",
      ruleForm: {
        name: "",
        industry: "",
        personalWx: "",
        companyWx: "",
        companyId: 0,
        email: "",
        position: "",
        createCompanyName: "",
        customerRemark: "",
        customerState: 1,
        phone:''
      },
      rules: {
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        personalWx: [
          { required: true, message: "请输微信号", trigger: "blur" },
        ],
      },
      labelTab: [
        {
          title: "添加标签",
          icon:'el-icon-circle-plus-outline',
          type: 1,
        },
        {
          title: "编辑标签",
          icon:'el-icon-edit-outline',
          type: 2,
        },
      ], // 标签实例
      isBoss: false,
      currentType: 0, // 点击的标签类型
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      addDialogDetailVisible: false, //添加标签选框显隐
      editDialogDetailVisible: false, //编辑标签选框显隐
      tab: [{},{},{}], //tab
      onConfirmTab: [{},{},{}],
      tags_lists: [], // 标签组
      tags_lists_old:[], //存储的老标签
      predefineColors: [
        "#FD634E",
        "#FFA133",
        "#2BCF88",
        "#51CDCB",
        "#566C84",
        "#F5F5F6",
      ], //预定颜色
      clientLable:'',
      saveLable:false
    };
  },
  created(){
    let info = this.$store.state.loginRoot.userInfo
    this.isBoss = info.roleId != 0;
    this.cardId = this.$route.query.id
    if(this.cardId){
      this.getCustomer()
    }
    if(JSON.parse(localStorage.getItem('isSourceAdmin'))) {
      this.hasPermi = false
    }

    
  },
  watch:{
    tags_lists(val){
      let than = this
        val.forEach((item,key)=>{
          than.onConfirmTab.forEach(v=>{
            item.list.forEach((v2,k) => {
              if(v.lableId ==  v2.lableId){
                v2.isSelect = true
                than.tab[key] = item.list[k]
              }
            });
          })
          
        })
    }
  },
  mounted() {
    this.getClientLabel();
  },
  methods: {
    handleRemove(file) {
      console.log(file);
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    selectFile(file) {
      this.ruleForm.logo = file?.url;
    },

    // 标签按钮
    labelTab_tap(index) {
      this.currentType = index;
      if (index == 0) {
        this.addDialogDetailVisible = true;
      } else {
        this.editDialogDetailVisible = true;
      }
    },

    //添加标签单个标签
    addLabel(idx) {
      if (this.tags_lists[idx].list.length > 5) {
        this.$message({
          title: "警告",
          message: "标签最多添加六个",
          type: "warning",
        });
        return;
      }
      this.tags_lists[idx].list.push({ lableName: "", lableColor: "#F5F5F6" });
    },
    //添加标签组
    addLabels() {
      if (this.tags_lists.length > 2) {
        this.$message({
          title: "警告",
          message: "标签组最多添加三个",
          type: "warning",
        });
        return;
      }
      this.tags_lists.push({
        lableName: "",
        list: [{ lableName: "", lableColor: "#F5F5F6" }],
      });
    },
    //删除标签事件  idx是标签组  k是小标签
    delete_label(idx, k) {
      if (!k && k !== 0) {
        this.tags_lists.splice(idx, 1);
      } else {
        this.tags_lists[idx].list.splice(k, 1);
      }
    },
    color_picker(lableColor, item) {
      const reg = /[0-9]\d+/g;
      const getArr = lableColor.match(reg);
      let hexStr =
        "#" + ((getArr[0] << 16) | (getArr[1] << 8) | getArr[2]).toString(16);
    },
    //获取客户标签
    getClientLabel() {
      fetchClient({ ignore: true })
        .then((res) => {
          let data = res.data;
          if (data) {
            this.tags_lists = [];
            let datas = [];
            data.forEach(function (i1, k1) {
              datas.push({
                lableName: i1.lableName,
                list: [],
                id: i1.lableId  || '',
                lableId: i1.lableId  || '',
              });
              i1.lableResponses &&
                i1.lableResponses.forEach(function (i2, k2) {
                  datas[k1].list.push({
                    lableName: i2.lableName,
                    lableColor: i2.lableColor,
                    id: i2.lableId  || '',
                    lableId: i2.lableId || '',
                    isSelect:false
                  });
                });
            });

            this.tags_lists = datas;
            this.tags_lists_old = JSON.parse(JSON.stringify(datas))
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCustomer(){
      let data  = {
        cardId: this.cardId
      }
      getCustomerInfo(data)
      .then(res=>{
        this.clientLable = res.data.lableResponseList
        this.ruleForm = Object.assign({}, this.ruleForm, res.data);
        console.log(this.ruleForm )
        
        this.onConfirmTab = res.data.lableResponseList ? res.data.lableResponseList.map(val => {
              return {
                id: val.lableId || '',
                lableName: val.lableName,
                lableColor: val.lableColor,
                lableId: val.lableId || ''
              }
            }) : [{}, {}, {}];

      })
    },
    //选择标签
    chooseTag(item, index , k ) {
      this.tags_lists[index].list.forEach((val,index)=>{
        if(index !== k){
          val.isSelect = false
        }
      })
      this.$set(this.tags_lists[index].list[k],'isSelect',!this.tags_lists[index].list[k].isSelect)
      this.tab[index] = this.tags_lists[index].list[k].isSelect ? this.tags_lists[index].list[k] : ''
      console.log(this.onConfirmTab)
    },
    //重置lable
    resetTap(){
      for(var i in this.tags_lists){
        this.tags_lists[i].list.forEach((val,index)=>{
          val.isSelect = false
        })
      }
      this.tab = [{},{},{}]
      this.onConfirmTab = []
    },
    //保存lable
    onConfirmTap(){
      this.onConfirmTab = JSON.parse(JSON.stringify(this.tab))
      this.addDialogDetailVisible = false
    },
    //保存标签
    saveTagsTree(){
      this.saveLable = true
      addLableList({list:this.tags_lists})
      .then(res=>{
        if(res.data){
          this.$message({
            type: "success",
            message: "操作成功",
          })
          this.getClientLabel()
          this.editDialogDetailVisible = false
        }
      })
      .catch(err=>{
        if(err.code == 210){
          this.$message.error(err.message)
        }
      })
    },
     /**@method 提交表单 */
    submitForm(formName) {
      let than = this
      console.log(this.ruleForm)
      let email = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]*\.)+[A-Za-z]{2,14}$/,
          phonenumber = /^0?(13|14|15|16|17|18|19)[0-9]{9}$/

      if(!this.ruleForm.name){
        this.$message({
          title: "提示",
          message: "请填写客户姓名",
          type: "warning",
        })
        return
      }
      if(this.ruleForm.phone){
      }else{
        this.$message({
          title: "提示",
          message: "请填写客户手机号",
          type: "warning",
        })
        return
      }
      if(this.ruleForm.phone && !phonenumber.test(this.ruleForm.phone)){
        
        this.$message({
          title: "提示",
          message: "请填写正确的电话号码",
          type: "warning",
        })
        return
      }
      if(this.ruleForm.email && !email.test(this.ruleForm.email)){
        this.$message({
          title: "提示",
          message: "请填写正确的邮箱",
          type: "warning",
        })
        return
      }
      var _this = this;
      var param = this.ruleForm

      param.lableIds = this.onConfirmTab.filter(val => {
        return val.id;
      }) || '';
      param.lableIds = param.lableIds ? param.lableIds.map(val => parseInt(val.id)) : [];
      if(this.clientLable){
        param.lableResponseList = this.clientLable
      }
      if(_this.cardId){

        updCustomer(param)
        .then(res=>{
          _this.$message({
            type:'success',
            message:_this.cardId ? '修改成功' : '添加成功'
          })
          if(_this.cardId){
              than.$router.go(-1)
          }else{

          }

        })
        .catch(err=>{

          if (err.code == 201) {
              let data;
              try {
                data = JSON.parse(err.message);

              } catch (e) {
                data = {};
              }

              if (data.type) {
                if (data.type == '1') {
                  than.$message({message:'该用户是本公司员工，不能添加为客户',type:'warning'});
                } else if (data.type == '2') {
                  than.$message({message:'该客户已被成员' + data.data + '独占，请与他取得联系',type:'warning'});
                } else if (data.type == '3') {

                  this.$confirm("共享客户池已存在该客户，前去抢为独占", "提示", {
                      onConfirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "info ",
                    })
                    .then(() => {
                      this.$router.push('/custom/detail?id=' + data.data)
                    })
                    .catch((err) => {});

                } else if (data.type == '4') {
                  than.$message({message:'该客户已被您独占',type:'warning'});
                }
                else if (data.type == '10') {
                  than.$message({message:data.data,type:'warning'});
                }
              }else{
                than.$message({message:err.message,type:'warning'});
              }
            }
        })
      }else{
        manualAdd(param)
        .then(res=>{
           this.$message({
            type:'success',
            message: '添加成功'
          })
          than.$router.push('/customerManagement')
        })
        .catch(err=>{
          
          if (err.code == 201) {
            
              let data;
              try {
                data = JSON.parse(err.message);

              } catch (e) {
                data = {};
              }
              if (data.type) {
                if (data.type == '1') {
                  than.$message({message:'该用户是本公司员工，不能添加为客户',type:'warning'});
                } else if (data.type == '2') {
                  than.$message({message:'该客户已被成员' + data.data + '独占，请与他取得联系',type:'warning'});
                } else if (data.type == '3') {
                  
                  this.$confirm("共享客户池已存在该客户，前去抢为独占", "提示", {
                      onConfirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "info ",
                    })
                    .then(() => {
                      this.$router.push('/custom/detail?id=' + data.data)
                    })
                    .catch((err) => {});

                } else if (data.type == '4') {
                  than.$message({message:'该客户已被您独占',type:'warning'});
                }
              }else{
                than.$message({message:err.message,type:'warning'});
              }
            }
        })
      }
      
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     alert("submit!");
      //   } else {
      //     console.log("error submit!!");
      //     return false;
      //   }
      // });
    },
    editDialogClose(){
      if(!this.saveLable){
        this.tags_lists = JSON.parse(JSON.stringify(this.tags_lists_old))
      }
    },
    //取消
    resetEditDialog(){
      if(!this.saveLable){
        this.tags_lists = JSON.parse(JSON.stringify(this.tags_lists_old))
      }
      this.editDialogDetailVisible = false
    }
  },
};
</script>

<style scoped>
.corporateInformation-view {
  padding: 16px 24px;
}
/deep/.el-tabs__content {
  background: #ffffff;
}

.ruleForm {
  margin-top: 24px;
}
.cancel {
  border: 1px solid rgba(81, 203, 205, 0.4);
  color: #51cbcd;
}
.save {
  background: #51cbcd;
  border: 1px solid #51cbcd;
}
.edit-label {
  position: relative;
}
.add-label {
  position: absolute;
  bottom: calc(100% - 40px);
  left: 0;
  width: 300px;
  height: 300px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  z-index: 9;
  background: #ffffff;
}
.select-label {
  padding:0 20px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .el-dialog__headerbtn {
    display: none;
}
/deep/ .el-color-dropdown__value{
  display: none;
}
/deep/.color-picker .el-input{
  display: none !important;
}
</style>
